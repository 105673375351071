<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="heading_alignment">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col class="col-12 col-sm-8 col-md-8 col-lg-6 mb-3">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col class="col-12 col-sm-4 col-md-4 col-lg-6 mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="adminActivityLogList()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="adminActivityLogObjList && adminActivityLogObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="adminActivityLogObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(activity_log)="data">
                    <p v-html="data.item.activity_log" class="threeLineOnly ">
                    </p>
                    <b-button variant="" size="sm" @click="adminActivityLogView('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="fa-solid fa-eye mr-0"></i>
                    </b-button>
                  </template>
                  <template v-slot:cell(user_name)="data">
                    {{data.item.user_name}}<br>
                    Email: {{data.item.user_email}}<br>
                    <Small>Id: {{data.item.user_id_responsible}}</Small>
                  </template>
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="showModelAdminActivityLogView"
      scrollable
      :title="cvEditModalHeader">
      <AdminActivityLogView :propOpenedInModal="true" :propAdminActivityLogObj="adminActivityLogViewObj" @emitCloseAdminActivityLogViewModal="closeAdminActivityLogViewModal" />
      <!-- <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="adminActivityLogView('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeAdminActivityLogViewModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template> -->
    </b-modal>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<style scoped>
.dateRange {
  float: right;
}
</style>

<script>
import { AdminActivityLogs } from "../../../FackApi/api/adminActivityLog.js"
import AdminActivityLogView from "./AdminActivityLogView"
import moment from "moment"

export default {
  name: "AdminActivityLogList",
  components: {
    AdminActivityLogView
  },
  data () {
    return {
      cvCardTitle: "AdminActivityLogs",
      cvSearchText: "Type to Search",
      cvEditModalHeader: "View Admin Activity Log",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "AdminActivityLog List Response",
      showModelAdminActivityLogView: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left", sortable: true },
        { label: "Action Performed By", key: "user_name", class: "text-left", sortable: true },
        { label: "Activity log", key: "activity_log", class: "text-left", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left", sortable: true }
      ],
      adminActivityLogObjList: null,
      adminActivityLogViewObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      }
    }
  },
  async mounted () {
    await this.getDateFilter()
    this.adminActivityLogList()
  },
  computed: {
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  methods: {
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
     */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * adminActivityLogList
     */
    async adminActivityLogList () {
      try {
        this.setDateFilter()
        let adminActivityLogListResp = await AdminActivityLogs.adminActivityLogList(this, this.whereFilter)
        if (adminActivityLogListResp.resp_status) {
          this.adminActivityLogObjList = adminActivityLogListResp.resp_data.data
          this.totalRows = adminActivityLogListResp.resp_data.count
        }
        else {
          this.toastMsg = adminActivityLogListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at adminActivityLogList() and Exception:", err.message)
      }
    },
    /**
     * AdminActivityLogView
     */
    adminActivityLogView (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/adminActivityLog_view/" + this.adminActivityLogViewObj.acl_id)
        }
        else {
          this.adminActivityLogViewObj = item
          this.showModelAdminActivityLogView = true
        }
      }
      catch (err) {
        console.error("Exception occurred at AdminActivityLogView() and Exception:", err.message)
      }
    },
    /**
     * closeAdminActivityLogViewModal
     */
    closeAdminActivityLogViewModal () {
      try {
        this.showModelAdminActivityLogView = false
      }
      catch (err) {
        console.error("Exception occurred at closeAdminActivityLogViewModal() and Exception:", err.message)
      }
    }
  }
}
</script>
